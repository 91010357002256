$(window).on('scroll', () => {
  const scrollTop = $(window).scrollTop();

  if (scrollTop < 5) {
    $('.register-popup').stop().animate({ height: '417' }, 150);
  } else if (scrollTop > 5 && scrollTop < 300) {
    $('.register-popup').stop().animate({ height: '50%' }, 150);
    // $('.register-popup').css("opacity","0.8");
    $('.register-popup').css('background-color', 'rgba(0,0,0,0.8)');
  } else {
    $('.register-popup').stop().animate({ height: '100%' }, 150);
    // // $('.register-popup').css("opacity","0.1");
    $('.register-popup').css('background-color', 'rgba(0,0,0,0.95)');
  }
});
